@media(max-width:767px){
  .div-y4c-link{
    float: none;

    a{
      position: static;
    }
  }
  .sf-images{
    width: 100%;
    font-size: 0;
    .sf-sub-image{
      background: #fff;
      width: 50%;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 10px;
    }
  }
}
