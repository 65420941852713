.home-banner{
  background: #27566E;
  position: relative;
  height: 574px;

  @include media(x-small){
     height: 700px;
   }

   @include media(small){
     height: 490px;
   }

   @include media(medium){
     height: 550px;
   }

   @include media(large){
     height: 700px;
   }

  .preloader{
    position: absolute;
    left: 0;
    top:35%;
    width: 100%;
    bottom: 35%;
    transition: all 0.3s;

    &.hide{
      opacity: 0;
      visibility:hidden;
    }
  }
  .flexslider{
    padding: 0;
    margin: 0;
    border: 0;

    .flex-control-paging{
      text-align: center;
      left: 0;
      width: 100%;
      margin: 0;
      position: absolute;
      padding: 0;
      bottom: 20px;

        @include media(x-small){
          bottom: 30px;
        }

      li{

        a{
          background: #B9B9B9;
          width: 14px;
          height: 14px;
          border: 1px solid rgba(255,255,255,0.3);
          &.flex-active{
            background: #0E5F58;
          }
        }
      }

    }
  }
  .full-video{

  }
  .slides{


    li{
      height: 68vh;
      overflow: hidden;

      @include media(x-small){
         height: 700px;
       }

       @include media(small){
         height: 490px;
       }

       @include media(medium){
         height: 550px;
       }

       @include media(large){
         height: 700px;
       }


      .full-video{
        height: 100%;
        position: relative;
        transition: all 1.2s;
        visibility: hidden;
        opacity: 0;

        video{
          position: absolute;
          left: 0;
          top:50%;
          transform: translateY(-50%);
        }

      }

      video{
          width: 100%;
          display: block;
          opacity: 0;
          transition: all 0.5s;
          visibility: hidden;
      }

      .btn-2022{
        position: absolute;
        right: 20px;
        bottom: 40px;
        transform: translateY(50px);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s;
        color: #fff;
        text-decoration: none;
        z-index: 80;
        padding: 8px 15px 8px;
        line-height: 1;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;

        @include media(x-small){
          right: 40px;
          padding: 10px 20px 8px;
          bottom: 40px;
          font-size: 18px;
        }

        span{
          transition:all 0.2s;
          transform: translate(0px,-1px);
          vertical-align: middle;
          display: inline-block;
        }
      }

      &.flex-active-slide{


        .full-video{
          opacity: 1;
          visibility: visible;

          video{
            opacity: 1;
            visibility: visible;
          }
        }

        .btn-2022{
          opacity: .7;
          visibility: visible;
          transform: translateY(0);

          &:hover{
            opacity: 1;
            span{
              transform: translate(5px,-1px);
            }
          }
        }
      }
    }
  }
}
