@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

// screen sizes
$x-small: 768px; // 13 inches
$small: 992px;
$medium: 1200px;  // 17 inches
$medium-large: 1400px;
$large: 1900px; // 24 inches?
$x-large: 2300px; // 27 inches
$max-ipad: 768px; // mobile devices
$max-small: 414px; // mobile devices

// responsivity
@mixin media($screen-type) {
  @if $screen-type == 'x-small' {
    @media (min-width: $x-small ) { @content; }
  }
  @if $screen-type == 'small' {
    @media (min-width: $small ) { @content; }
  }
  @if $screen-type == 'medium' {
    @media (min-width: $medium) { @content; }
  }
  @if $screen-type == 'medium-large' {
    @media (min-width: $medium-large) { @content; }
  }
  @if $screen-type == 'large' {
    @media (min-width: $large) { @content; }
  }
  @if $screen-type == 'x-large' {
    @media (min-width: $x-large) { @content; }
  }


  @if $screen-type == 'max-ipad' {
    @media (max-width: $max-ipad) { @content; }
  }

  @if $screen-type == 'max-small' {
    @media (max-width: $max-small) { @content; }
  }


}

// antialiasing mode font rendering
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin transition($val) {
  -webkit-transition: $val;
  transition: $val;
}

@mixin border-radius($val) {
  -webkit-border-radius: $val;
  border-radius: $val;
}

@mixin box-shadow($val) {
  -webkit-box-shadow: $val;
  box-shadow: $val;
}

//Linear gradient

@mixin linear-gradient($from, $to) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient($from, $to);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient($from, $to);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient($from, $to);
}
