.headercontainer{
  position: static;
  display: inline;
}
#header{
  z-index: 60;
  transition: all 0.3s;
  width: 100%;
  position: fixed;
  left: 0;
  top:0;
  width: 100%;

  .scroll &{

    z-index: 90;
  }
}
body{
  padding-top: 80px;

  @include media(small){
    padding-top: 170px;
  }

  @include media(medium){

  }

  @include media(large){

  }
}
ul#nav{

  @include media(x-small){
    display: table;
    float: none;
    margin: 0 auto;
  }

}
html.full-height,
body.full-height{
  height: 100%;
}
body.full-height{
  overflow: hidden;
}
body{
  .section-wrap{
    display: none;

    &.show{
      display: block;
    }

  }

}
#intro-video{
  height: 100vh;
  display: block;
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  transition: all 0.5s;

  &.off{
    visibility: hidden;
    opacity: 0;

  }

  .vid-container{
    width: 100%;
    position: absolute !important;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;

    &.show{
      visibility: visible;
      opacity: 1;
    }

    .video{
      width: 100%;
    }
  }

  .video-enter{
    position: absolute;
    top:50%;
    width: 100%;
    transform: translateY(-50%);
    z-index: 81;
    color: #fff;




    &.show{


      span{
        opacity: 1;
        visibility: visible;
        transform: translate(-50%,-50%);
      }
    }

    span{
      letter-spacing: 5px;
      font-size:20px;
      font-weight: 300;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 1px solid #fff;
      cursor: pointer;
      display: block;
      position: absolute;
      left: 50%;
      top:50%;
      transition: all 0.5s;
      padding: 44px 0;
      text-align: center;
      top:0;
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%,-20%);
    }
  }
}

.section{
  position: relative;
  padding: 60px 0;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;


  @include media(x-small){
    padding: 80px 0;
  }

  >.container{
    position: relative;
  }

  &.banner{
    padding: 0;
    height: 900px;


  }

  .section-body{
    font-family: 'din-condensed';
    position: relative;
    z-index: 50;

    p{
      margin-bottom: 30px;
    }

    h3{
      margin-bottom: 30px;
    }
  }

  .blurb{



    p{
      font-weight: 300;
      font-size: 20px;
      margin-bottom: 30px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    &.blurb-box{
      max-width: 1000px;
      margin: 0 auto;
    }

  }

  .section-heading{
    margin-bottom: 30px;

    span{
      display: block;
    }
     h2{
       font-size: 35px;

       @include media(x-small){
         font-size: 40px;
       }

       span{
         color: #fff;
         font-size: 20px;

         @include media(x-small){
           font-size: 30px;
         }
       }

       img{
         display: inline;
         vertical-align: middle;
       }
     }

     &.text-centered{
       text-align: center;
     }
  }

}

.section.inverted{
    color: #fff;
    background-color: #20568a;
    h3,
    h2{
      color: #fff;
    }

    .section-title h2{
      color: #fff;
      font-size: 50px;
    }

    .row-profile{

      .col-exec{
        p{
          margin-bottom: 20px;
        }
      }
      .info{
        color: #FFFFFF;
        padding-left: 15px;
        float: left;
        @include media(small){
          padding: 0 30px;
          float: none;
        }

        h3{
          color: #fff;
          font-weight: 300;
          font-size: 18px;
          line-height: 1.4;

          strong{
            font-weight: 600;
            font-size: 22px;
          }

        }

      }
    }

}


.section.logos{
  display: block;
}
.section.irs{

  .gfx-earth{
      img{
        max-width: 100%;
      }
  }
}
.section.bod{
  color: #000000;

  .building{
     position: absolute;
     bottom: -80px;
     z-index: 0;
     left: -180px;
     pointer-events: none;

     @include media(x-small){
       left: -100px;
     }

     @include media(small){
       left: 0px;
     }

     @include media(medium){
       left: 100px;
     }
   }

  .circle-1{
     position: absolute;
     top:-70px;
     left: 0;
     z-index: 0;
     pointer-events: none;
   }

   .blurb{
     margin-bottom: 30px;
   }

   .section-heading{

     h2{
       color: #367489;

       span{
         color: #367489;
       }
     }
   }
   .row-profile .info h3{
     color: #000;
   }

}
.section.banner{
  height: 400px;
  background-position: center;


  @include media(x-small){
    height: 500px;
  }

  @include media(small){
    height: 600px;
  }

  @include media(medium){
    height: 870px;
  }

  >.container{
    height: 100%;
  }

  h2{
      text-shadow:5px 3px rgba(0,0,0,.4);
      font-size: 30px;
      padding-top: 100px;

      @include media(x-small){
        font-size: 40px;
        padding-top: 30px;
      }
      @include media(small){
        font-size: 50px;
        padding-top: 0;
      }

      @include media(medium){
        font-size: 65px;
      }
  }
  h3{
    text-shadow:5px 3px rgba(0,0,0,.4);
  }

  a{
    text-decoration: none;
    color: #fff;
    text-decoration: underline;

  }
  .btn-link-2022{
    text-decoration: none;
    line-height: 1;

    .w-icon-slider-right{
      vertical-align: middle;
      transition: all 0.2s;
      transform: translateX(0);
      display: inline-block;
    }

    &:hover{
      .w-icon-slider-right{
        transform: translateX(10px);
      }
    }
  }
}


.home{


}
.playbutton{
  line-height: 1;
}
