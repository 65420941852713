.clear-fix{
  clear: both;
}
h2{
  color: #707070;
}

.img-shadow{
  box-shadow: 10px 10px rgba(255,255,255,0.11);
}
.disp-table{
  display: table;
  width: 100%;
  height: 100%;

  .disp-cell{
    display: table-cell;
    vertical-align: middle;
    }
}

.title{
  border: 0;
  padding-left: 0;
}
.btn-group{

  .btn-2022{
    margin-right: 1px;
    margin-bottom: 10px;
    display: inline-block;

    @include media(x-small){
      margin-right: 20px;
    }

    & + .btn-2022{
      margin-right: 0;
    }
  }
}
.no-margin-bottom{
  margin-bottom: 0 !important;
}
.btn-2022{
  background: #289D79;
  color: #fff;
  font-size: 19px;
  font-family: 'din-condensed';
  padding:8px 15px;
  border-radius: 25px;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.2s;

  @include media(x-small){
    font-size: 20px;
  }

  &:hover{
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
  }

  @include media(x-small){
    font-size: 22px;
    padding:10px 30px;
  }
}



.row-profile{

  max-width: 100%;

  &::after{
    clear: both;
    display: block;
    content: "";
  }

  @include media(small){
    margin: 0 auto;
    width: 550px;
  }

  img{
    max-width: 100%;

    @include media(small){
      max-width: 192px;
    }
  }

  .col-exec{
    clear: both;

    @include media(small){
      width: 240px;
      float: left;
      clear: none;
    }

    &:nth-child(2){

      @include media(small){
        float: right;
      }
    }

    &::after{
      clear: both;
      display: block;
      content: "";
    }

    &:last-child{
      float: right;
    }

    .cdl-thumb{

      float: left;
      width: 40%;

      @include media(small){
        float: none;
        width: auto;
      }
    }
  }

  .info{
    margin-bottom: 30px;
    float: left;
    width: 60%;
    margin-top: 30px;

    @include media(small){
      float: none;
      width: auto;
      margin-top: 0;
    }
  }

  .btn-2022{



  }

}



.logos{

  .w-slide{

    a{
      color: #05668D;
      text-decoration: none;
    }
  }
}

.sdg-list{
  list-style: none;
  padding: 0;
  margin: 20px -2px;
  font-size: 0;

  @include media(small){
      margin: 20px -4px;
  }

  li{
    display: inline-block;
    margin: 1px;

    @include media(small){
      margin: 1px;
    }
    a{

      &:hover{

        img{
          transform: scale(1.1);
        }
      }

      img{
        transition: all 0.3s;
        transform: scale(1);
        max-width: 69px;

        @include media(x-small){
            max-width: 74px;
        }
      }
    }
  }
}

.boxed-photo{
  height: 300px;
  position: relative;
  background: #fff;
  margin-bottom: 20px;

  img{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
  }
}

.logo-flexslider{

  html &{
    background: 0 !important;
    padding: 50px 0;

    .slides{

      li{

        a{
          display: block;
          text-align: center;
          text-decoration: none;

          .img-holder{
            display: inline-block;
            height: 90px;
            margin: 0 auto;
            text-align: center;
            position: relative;

            img{
              max-height: 90px;
              width: auto;
              display: inline;

            }
          }

          .line{
            width: 1px;
            height: 60px;
            background: #707070;
            display: block;
            opacity: 0.5;
            margin: 10px 0px 10px 4px;
          }
          .s-title{
            display: block;
            padding-top: 30px;
            text-align: center;
            color: #05668D;


          }

        }
      }
    }

    .flex-control-nav {
      width: 100%;
      bottom: -20px;

      @include media(x-small){
        bottom: 0;
      }

      li{

        a{
          background: #B9B9B9;
          &.flex-active{
            background: #0E5F58;
          }
        }
      }

    }
  }
}
/*
.sf-images{
  width: 100%;
  font-size: 0;

  @include media(x-small){
    display: flex;
  }
  .sf-sub-image{
    width: 50%;
    margin: 0;
    display: inline-block;
    vertical-align: middle;

    @include media(x-small){
      width: auto;
    }

    img{

      @include media(x-small){
        height: 82px;
        width: auto;
      }
    }
  }
}*/
